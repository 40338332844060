import { useParams } from "react-router-dom";
import {
  changeSelectedCategory,
  defaultPosts,
  getCategoryPosts,
} from "./slice";
import React, { useEffect, useState } from "react";

import Posts from "./posts";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

const CategoryPosts = () => {
  let { category } = useParams();
  // 为了让url好看一些，所有文章使用的url是/categories/all, 这边需要转成 categoryId
  const categoryId = category === "all" ? 0 : category;
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.app.categories);
  const selectedCategory = useSelector((state) => state.app.selectedCategory);

  const [posts, setPosts] = useState(defaultPosts);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const changeCategory = async () => {
      setLoading(true);
      //更换类别后，需要把页码置为1
      setCurrentPage(1);

      const queryContent = (
        await getCategoryPosts(categoryId, 0, currentPageSize)
      ).data.content;
      const { data, total } = queryContent;

      const category = _.find(categories, ["id", categoryId]);
      if (!_.isUndefined(category)) {
        dispatch(changeSelectedCategory(category));
      }

      setPosts(data);
      setTotal(total);
      setLoading(false);
    };
    changeCategory();
  }, [categories, categoryId, currentPageSize, dispatch]);

  const changePage = async (page, pageSize) => {
    setLoading(true);
    setCurrentPage(page);
    setCurrentPageSize(pageSize);
    const data = (await getCategoryPosts(categoryId, page - 1, pageSize)).data
      .content.data;
    setPosts(data);
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{selectedCategory?.name}</title>
      </Helmet>
      <Posts
        containerTitle={selectedCategory?.name}
        posts={posts}
        total={total}
        currentPage={currentPage}
        currentPageSize={currentPageSize}
        changePage={changePage}
        loading={loading}
      />
    </>
  );
};

export default CategoryPosts;
