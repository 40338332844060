import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getQueryParams } from "../common/router";

export const ALL_CATEGORIES_ITEM = {
  id: "0",
  name: "所有文章",
  parentId: "0",
  children: null,
  path: "/categories/all",
};

const initialState = {
  basicSearchInput: "",
  advanceSearchInput: {},
  selectedCategory: ALL_CATEGORIES_ITEM,
  categories: [],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    changeBasicSearchInput: (state, action) => {
      state.basicSearchInput = action.payload;
    },
    changeAdvanceSearchInput: (state, action) => {
      state.advanceSearchInput = action.payload;
    },
    changeSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeBasicSearchInput,
  changeSelectedCategory,
  changeAdvanceSearchInput,
  setCategories,
} = appSlice.actions;

export default appSlice.reducer;

export const isAdvanceSearch = (location) => {
  const { keyword } = getQueryParams(location);
  return _.isUndefined(keyword);
};

export const getCategories = () => {
  return axios.get("/categories");
};

export const getCategoryPosts = (categoryId, page = 0, size = 10) => {
  return axios.get("/external/posts", {
    params: {
      categoryId,
      page,
      size,
    },
  });
};

export const searchPosts = (keyword, page = 0, size = 10) => {
  return axios.get("/external/posts/search", {
    params: {
      keyword,
      page,
      size,
    },
  });
};

export const advanceSearchPosts = (params, page = 0, size = 10) => {
  return axios.get("/external/posts/advance-search", {
    params: {
      ...params,
      page,
      size,
    },
  });
};

export const getAllSelections = () => {
  return axios.get("/selections");
};

// Just some placeholders to show Skeleton
export const defaultPosts = [];
for (let i = 0; i < 5; i++) {
  defaultPosts.push({
    id: `Hermes post ${i}`,
    path: `Hermes post ${i}`,
    title: `Hermes post ${i}`,
    content: "Hermes content",
    status: {
      displayName: "displayName",
      name: "name",
    },
  });
}
