import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { history } from "../common/router";
import { message } from "antd";
import _ from "lodash";

const initialState = {
  udeskUserInfo: {},
  spinStatus: false,
  confirmLoading: false,
  infoModalVisible: false,
};

export const udeskSlice = createSlice({
  name: "udesk",
  initialState,
  reducers: {
    changeUdeskUserInfo: (state, action) => {
      state.udeskUserInfo = action.payload;
    },
    changeSpinStatus: (state, action) => {
      state.spinStatus = action.payload;
    },
    changeConfirmLoading: (state, action) => {
      state.confirmLoading = action.payload;
    },
    changeInfoModalVisible: (state, action) => {
      state.infoModalVisible = action.payload;
    },
  },
});

export const {
  changeUdeskUserInfo,
  changeSpinStatus,
  changeConfirmLoading,
  changeInfoModalVisible,
} = udeskSlice.actions;

export default udeskSlice.reducer;

export const getUdeskUserInfo = (token) => (dispatch) => {
  axios
    .get("/udesk-user/info", { params: { customer_token: token } })
    .then((res) => {
      dispatch(changeUdeskUserInfo(res.data.content));
    })
    .catch((err) => {});
};

export const updateUserInfo = (user) => {
  return axios.post("/udesk-user/info", user);
};
export const udeskUserLogin = (user, from) => (dispatch) => {
  dispatch(changeSpinStatus(true));
  axios
    .post("/udesk-user/login", user)
    .then((res) => {
      dispatch(changeSpinStatus(false));
      const customer = res.data.content;
      dispatch(changeUdeskUserInfo(customer));
      const isFirstLogin = localStorage.getItem("IS_FIRST_LOGIN");
      if (!isFirstLogin) {
        const phones = customer.phones;
        const company = customer.company;
        if (!phones || !company) {
          message.warn("登录后请完善手机和单位名称", 2);
        }
        localStorage.setItem("IS_FIRST_LOGIN", "true");
      }
      history.push(from ? from : "/udesk/home");
    })
    .catch((err) => {
      dispatch(changeSpinStatus(false));
    });
};

export const udeskUserRegister = (user) => (dispatch) => {
  dispatch(changeSpinStatus(true));
  axios
    .put("/udesk-user/register", user)
    .then((res) => {
      dispatch(changeSpinStatus(false));
      message.success("您已经成功注册,系统将会自动跳转到登录页面", 1);
      _.delay(() => {
        history.push("/udesk/login");
      }, 1000);
    })
    .catch((err) => {
      dispatch(changeSpinStatus(false));
    });
};

export const getCaptcha = (email, type) => () => {
  axios
    .get("/udesk-user/captcha", { params: { email, type } })
    .catch((err) => {});
};

export const udeskUserPassReset = (user) => (dispatch) => {
  dispatch(changeSpinStatus(true));
  axios
    .post("/udesk-user/pass", user)
    .then((res) => {
      dispatch(changeSpinStatus(false));
      message.success("您已经成功重置密码,系统将会自动跳转到登录页面", 1);
      _.delay(() => {
        history.push("/udesk/login");
      }, 1000);
    })
    .catch((err) => {
      dispatch(changeSpinStatus(false));
    });
};

export const checkUserExist = (email) => {
  return axios.get("/udesk-user/exist", { params: { email } });
};
