import { Button, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ProForm, {
  ModalForm,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-form";
import {
  changeAdvanceSearchInput,
  changeBasicSearchInput,
  getAllSelections,
} from "./slice";
import { useMount } from "react-use";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getQueryParams } from "../common/router";
import _ from "lodash";
import { filterData } from "../common/utils";

const AdvanceSearch = () => {
  const [options, setOptions] = useState({
    authors: [],
    statuses: [],
    categories: [],
    tags: [],
    products: [],
    components: [],
    versions: [],
  });

  const formRef = useRef();

  const advanceSearchInput = useSelector(
    (state) => state.app.advanceSearchInput
  );

  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();

  useMount(async () => {
    const selections = (await getAllSelections()).data.content;
    const options = _.mapValues(selections, (rawOpts) => {
      filterData(rawOpts);
      return rawOpts.map((opt) => {
        return { label: opt, value: opt, key: opt };
      });
    });
    setOptions(options);
  });

  useEffect(() => {
    const advanceSearchParams = getQueryParams(location);
    dispatch(changeAdvanceSearchInput(advanceSearchParams));
  }, [location, dispatch]);

  return (
    <ModalForm
      formRef={formRef}
      initialValues={advanceSearchInput}
      title="高级搜索"
      trigger={<Button type="primary">高级搜索</Button>}
      submitter={{
        render: (props, defaultDoms) => {
          return [
            ...defaultDoms,
            <Button
              key="reset"
              onClick={() => {
                // 直接使用 props.reset()，会把 props 重置为 initialValues，而不会全部清空;
                // 所以这边需要自己写个方法把 FieldsValue 都置为空
                const fieldsValue = props.form?.getFieldsValue();
                const resetValue = _.mapValues(fieldsValue, () => {
                  return undefined;
                });
                props.form?.setFieldsValue(resetValue);
                dispatch(changeAdvanceSearchInput({}));
              }}
            >
              重置
            </Button>,
          ];
        },
      }}
      modalProps={{
        destroyOnClose: true,
      }}
      onFinish={(values) => {
        const searchParams = qs.stringify(values, {
          arrayFormat: "comma",
          encode: false,
        });
        history.push({
          pathname: "/search",
          search: searchParams,
        });
        dispatch(changeBasicSearchInput(""));
        dispatch(changeAdvanceSearchInput(values));
        message.success("提交成功");
        return true;
      }}
    >
      <ProForm.Group>
        <ProFormText width="md" name="title" label="文章标题" />
        <ProFormText width="md" name="content" label="文章内容" />
      </ProForm.Group>

      <ProForm.Group>
        <ProFormSelect.SearchSelect
          fieldProps={{
            labelInValue: false,
          }}
          options={options.categories}
          width="md"
          name="categories"
          label="分类"
        />
        <ProFormSelect.SearchSelect
          fieldProps={{
            labelInValue: false,
          }}
          options={options.tags}
          width="md"
          name="tags"
          label="标签"
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormSelect.SearchSelect
          fieldProps={{
            labelInValue: false,
          }}
          options={options.products}
          width="md"
          name="products"
          label="产品"
        />
        <ProFormSelect.SearchSelect
          fieldProps={{
            labelInValue: false,
          }}
          options={options.components}
          width="md"
          name="components"
          label="组件"
        />
      </ProForm.Group>

      <ProForm.Group>
        <ProFormSelect.SearchSelect
          fieldProps={{
            labelInValue: false,
          }}
          options={options.versions}
          width="md"
          name="versions"
          label="版本"
        />

        <ProFormSelect
          name="modifiedDate"
          label="更新时间"
          width="md"
          fieldProps={{
            labelInValue: false,
          }}
          valueEnum={{
            last_week: "过去一周内",
            last_month: "过去一个月内",
            last_year: "过去一年内",
          }}
        />
      </ProForm.Group>
    </ModalForm>
  );
};
export default AdvanceSearch;
