import React from "react";
import { Input, Layout, Typography } from "antd";
import background from "../background.png";
import styled from "@emotion/styled";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { changeAdvanceSearchInput, changeBasicSearchInput } from "./slice";
import Categories from "./categories";
import CategoryPosts from "./category-posts";
import SearchPosts from "./search-posts";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { ColumnCenterContainer, PostFooter } from "../common/components";
import qs from "qs";
import AdvanceSearch from "./advance-search";
import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";
import { getQueryParams } from "../common/router";
import _ from "lodash";
import { Header } from "../common/header";

const { Content, Sider } = Layout;
const { Title } = Typography;
const { Search } = Input;

const Home = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();

  const basicSearchInput = useSelector((state) => state.app.basicSearchInput);

  useMount(() => {
    const { keyword } = getQueryParams(location);
    if (!_.isUndefined(keyword)) {
      dispatch(changeBasicSearchInput(keyword));
    }
  });

  const onSearch = (value) => {
    history.push({
      pathname: "/search",
      search: qs.stringify({ keyword: value }),
    });
    dispatch(changeAdvanceSearchInput({}));
  };

  return (
    <Layout
      css={css`
        min-height: 100vh;
      `}
    >
      <BackgroundContainer>
        <Header />
        <ColumnCenterContainer
          css={css`
            height: 30rem;
          `}
        >
          <WhiteTitle>星环科技知识库</WhiteTitle>
          <WhiteTitle
            level={3}
            css={css`
              margin-top: -1rem !important;
            `}
          >
            Knowledge Base
          </WhiteTitle>
          <Search
            css={css`
              width: 40%;
              padding-bottom: 2rem;
            `}
            placeholder="请输入文章 标题/内容 进行搜索"
            onSearch={onSearch}
            onChange={({ target: { value } }) =>
              dispatch(changeBasicSearchInput(value))
            }
            value={basicSearchInput}
            size="large"
            enterButton
            allowClear
          />
          <AdvanceSearch />
        </ColumnCenterContainer>
      </BackgroundContainer>
      <Content style={{ padding: "0 10rem" }}>
        <Layout style={{ padding: "24px 0" }}>
          <div
            css={css`
              height: auto;
            `}
          >
            <Sider
              width={200}
              css={css`
                background: #fff;
                min-height: 50rem;
                max-height: 100vh;
                margin: 9rem 0;
                overflow-y: auto;
                position: -webkit-sticky;
                position: sticky;
                top: 1rem;
              `}
            >
              <Categories />
            </Sider>
          </div>
          <Switch>
            <Redirect path={"/"} exact to={`/categories/all`} />
            <Route path={`/categories/:category`}>
              <CategoryPosts />
            </Route>
            <Route path={`/search`}>
              <SearchPosts />
            </Route>
            <Route path="*">
              <Redirect to={`/404`} />
            </Route>
          </Switch>
        </Layout>
      </Content>
      <PostFooter />
    </Layout>
  );
};

const BackgroundContainer = styled.div`
  background: url(${background}) no-repeat;
  background-size: 100% 100%;
  height: 35rem;
`;

const WhiteTitle = styled(Title)`
  color: white !important;
  display: block;
`;

export default Home;
