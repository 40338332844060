import { history } from "./common/router";
import { Route, Router, Switch } from "react-router-dom";
import Home from "./home";
import React, { Suspense, lazy } from "react";
import Loading from "./common/loading";

const Post = lazy(() => import("./post"));
const NotFoundPage = lazy(() => import("./404"));
const AppRouter = () => {
  return (
    <Router history={history}>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/posts/:id">
            <Post />
          </Route>
          <Route path="/404">
            <NotFoundPage />
          </Route>
          <Route path="/" component={Home} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
