import { ColumnCenterContainer } from "./components";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PacmanLoader } from "react-spinners";
import theme from "./theme";

const Loading = () => {
  return (
    <ColumnCenterContainer
      css={css`
        height: 80vh;
        margin-right: 20rem;
      `}
    >
      <PacmanLoader color={theme.lightPrimary} size={80} />
    </ColumnCenterContainer>
  );
};
export default Loading;
