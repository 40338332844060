import styled from "@emotion/styled";
import React from "react";
import { BackTop } from "antd";
import { Link } from "react-router-dom";
import { createFromIconfontCN } from "@ant-design/icons";

// 从 Iconfont 项目中导入图标
export const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_1523362_2kyowsqtegv.js",
});

export const PostFooter = () => {
  return (
    <>
      <BackTopContainer />
      <CenterFooter>
        版权所有：星环信息科技（上海）股份有限公司&emsp;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
        >
          沪ICP备 13042669号-4
        </a>
      </CenterFooter>
    </>
  );
};

export const ButtonMailto = ({ mailto, label }) => {
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};

const BackTopContainer = styled(BackTop)`
  margin-bottom: 7rem;
  padding-left: 2rem;
`;

const CenterFooter = styled.div`
  margin-top: 5rem;
  height: 50px;
  background: black;
  color: white;
  text-align: center;
  padding: 15px 0;
  font-size: 14px;
`;

export const ColumnCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RowCenterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const MarginContainer = styled.div`
  margin-top: 3rem;
`;
