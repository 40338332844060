import { configureStore } from "@reduxjs/toolkit";
import appReducer from "../home/slice";
import userReducer from "../login/slice";
import udeskReducer from "../udesk/slice";

export default configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    udesk: udeskReducer,
  },
});
