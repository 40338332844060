import { Link, useHistory } from "react-router-dom";
import { Button, Col, Row, Space } from "antd";
import logo from "../logo.png";
import React from "react";
import styled from "@emotion/styled";
import background from "../background.png";

export const HalfHeader = () => {
  return (
    <HalfBackgroundContainer>
      <Header />
    </HalfBackgroundContainer>
  );
};

export const Header = () => {
  const history = useHistory();

  return (
    <LogoContainer>
      <Row>
        <Col span={4} />
        <Col span={3}>
          <Link to={"/"}>
            <img src={logo} alt="Logo" />
          </Link>
        </Col>
        <Col>
          <Space>
            <NoBorderButton ghost>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://success.transwarp.cn"
              >
                客服平台
              </a>
            </NoBorderButton>
            <NoBorderButton ghost>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.transwarp.cn/"
              >
                星环文档库
              </a>
            </NoBorderButton>
            <NoBorderButton ghost onClick={() => history.push("/posts/370")}>
              产品下载
            </NoBorderButton>
            <NoBorderButton ghost onClick={() => history.push("/posts/641")}>
              联系售后技术支持
            </NoBorderButton>
            <NoBorderButton ghost>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.transwarp.cn/contact"
              >
                联系星环科技
              </a>
            </NoBorderButton>
          </Space>
        </Col>
      </Row>
    </LogoContainer>
  );
};

const HalfBackgroundContainer = styled.div`
  background: url(${background}) no-repeat;
  background-size: 100%;
  height: 10rem;
`;

const NoBorderButton = styled(Button)`
  font-size: 15px;
  border: 0;
`;

const LogoContainer = styled.div`
  height: 5rem;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem;
`;
