import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import {
  advanceSearchPosts,
  defaultPosts,
  isAdvanceSearch,
  searchPosts,
} from "./slice";
import Posts from "./posts";
import { getQueryParams } from "../common/router";

const SearchPosts = () => {
  let location = useLocation();

  const [posts, setPosts] = useState(defaultPosts);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [loading, setLoading] = useState(true);

  const searchByType = useCallback(
    (page = 0, size = 10) => {
      if (isAdvanceSearch(location)) {
        const advanceSearchParams = getQueryParams(location);
        return advanceSearchPosts(advanceSearchParams, page, size);
      } else {
        const { keyword } = getQueryParams(location);
        return searchPosts(keyword, page, size);
      }
    },
    [location]
  );

  useEffect(() => {
    const handleSearch = async () => {
      setLoading(true);
      //把页码置为1
      setCurrentPage(1);
      // 前端的页数从1开始，而后端的页数从0开始
      const queryContent = (await searchByType(0, currentPageSize)).data
        .content;

      const { data, total } = queryContent;

      setPosts(data);
      setTotal(total);
      setLoading(false);
    };
    handleSearch();
  }, [searchByType, location, currentPageSize]);

  const changePage = async (page, pageSize) => {
    setLoading(true);
    setCurrentPage(page);
    setCurrentPageSize(pageSize);
    const queryContent = (await searchByType(page - 1, currentPageSize)).data
      .content;
    const { data } = queryContent;
    setPosts(data);
    setLoading(false);
  };

  return (
    <>
      <Posts
        containerTitle={"搜索结果"}
        posts={posts}
        total={total}
        currentPage={currentPage}
        currentPageSize={currentPageSize}
        changePage={changePage}
        loading={loading}
      />
    </>
  );
};
export default SearchPosts;
