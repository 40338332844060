import _ from "lodash";
import ManagerQrCode from "./pic/manager-qrcode.png";
import React from "react";

export const filterData = (data) => {
  _.pull(data, "All", "无合适选项（请联系Bryan）");
};

export const getAbstract = (content) => {
  const headlessContent = content.startsWith("概要")
    ? content.slice(4)
    : content;
  const index = headlessContent.indexOf("详细说明");
  return headlessContent.slice(0, index);
};

export const RATED_POST_IDS = "RATED_POST_IDS";

export const isPhone = (value) => {
  const phoneReg = /^[1][0-9]{10}$/;
  return phoneReg.test(value);
};

export const isEmail = (value) => {
  const emailReg = /(\w+\.)*\w+@\w+\.[a-z]+(\.[a-z]+)*/;
  return emailReg.test(value);
};

export const ContactAdmin = () => {
  return <img src={ManagerQrCode} alt={"管理员二维码"} />;
};

export const isNumberCaptcha = (value) => {
  const captchaReg = /^[0-9]{6}$/;
  return captchaReg.test(value);
};

export const clearTicketSDKElements = (root) => {
  let doms = null;
  doms = root.getElementsByTagName("script");
  for (let index = 0; index < doms.length; index++) {
    root.removeChild(doms[index]);
  }
  doms = root.getElementsByTagName("link");
  for (let index = 0; index < doms.length; index++) {
    root.removeChild(doms[index]);
  }
  doms = root.getElementsByClassName("udesk-sdk-ticket");
  for (let index = 0; index < doms.length; index++) {
    root.removeChild(doms[index]);
  }
};

export const clearImSDKElements = () => {
  const imContainer = document.getElementById("udesk_container");
  const imScripts = document.getElementsByClassName("clz-im-sdk");
  for (let index = 0; index < imScripts.length; index++) {
    imScripts[index].remove();
  }
  if (imContainer != null) {
    imContainer.remove();
  }
};

export const randomString = (len) => {
  len = len || 8;

  const t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  let value = "";
  for (let i = 0; i < len; i++) {
    value += t.charAt(Math.floor(Math.random() * t.length));
  }
  return value;
};
