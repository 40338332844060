import { PageContainer } from "@ant-design/pro-layout";
import _ from "lodash";
import { ColumnCenterContainer } from "../common/components";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Card, Empty, List, Skeleton, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import {
  ClockCircleTwoTone,
  EyeInvisibleTwoTone,
  EyeTwoTone,
} from "@ant-design/icons";
import theme from "../common/theme";
import { getAbstract } from "../common/utils";
import styled from "@emotion/styled";

const { Paragraph } = Typography;

const Posts = ({
  containerTitle,
  posts,
  total,
  currentPage,
  currentPageSize,
  changePage,
  loading,
}) => {
  return (
    <PageContainer
      ghost
      header={{
        title: containerTitle,
      }}
    >
      {_.isEmpty(posts) ? (
        <ColumnCenterContainer
          css={css`
            width: calc(100vw - 600px);
            height: 20rem;
          `}
        >
          <Empty description={"暂无文章"} />
        </ColumnCenterContainer>
      ) : (
        <List
          itemLayout="vertical"
          size="large"
          pagination={{
            total: total,
            current: currentPage,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} `,
            onChange: (page, pageSize) => {
              changePage(page, pageSize);
              // 滚动条置顶
              window.scrollTo(0, 0);
            },
            showSizeChanger: true,
          }}
          dataSource={posts}
          renderItem={(item) => {
            const {
              id,
              path,
              title,
              content,
              highlightContents,
              modifiedDate,
              status,
            } = item;
            const { displayName } = status;
            const abstract = getAbstract(content);

            const StatusIcon = ({ displayName }) => {
              return displayName === "已发布" ? (
                <EyeTwoTone twoToneColor={theme.primary} />
              ) : (
                <EyeInvisibleTwoTone twoToneColor={theme.danger} />
              );
            };

            return (
              <List.Item
                key={id}
                css={css`
                  width: calc(100vw - 500px);
                `}
              >
                <Skeleton active loading={loading}>
                  <Link to={path} target="_blank">
                    <Card
                      hoverable
                      title={
                        <div style={{ color: `${theme.primary}` }}>{title}</div>
                      }
                    >
                      {/*没有高亮内容，显示摘要*/}
                      {_.isEmpty(highlightContents) && (
                        <Paragraph
                          ellipsis={{
                            rows: 4,
                          }}
                        >
                          {abstract}
                        </Paragraph>
                      )}
                      {/*有高亮内容，显示高亮内容*/}
                      {!_.isEmpty(highlightContents) && (
                        <HighlightParagraph>
                          {highlightContents.map((content) => {
                            return (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: content,
                                }}
                              />
                            );
                          })}
                        </HighlightParagraph>
                      )}
                      <Space size={"large"}>
                        <Space size={"small"}>
                          <ClockCircleTwoTone />
                          <span>{modifiedDate}</span>
                        </Space>
                        <Space size={"small"}>
                          <StatusIcon displayName={displayName} />
                          {displayName}
                        </Space>
                      </Space>
                    </Card>
                  </Link>
                </Skeleton>
              </List.Item>
            );
          }}
        />
      )}
    </PageContainer>
  );
};

const HighlightParagraph = styled(Paragraph)`
  em {
    color: #dd4b39;
    font-style: normal;
  }
`;

export default Posts;
