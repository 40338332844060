import { createBrowserHistory } from "history";
import qs from "qs";
/** @jsxImportSource @emotion/react */

// 需要在组件外使用history
export const history = createBrowserHistory();

export const getQueryParams = (location) => {
  return qs.parse(decodeURIComponent(location.search), {
    // React router返回的query params包含开头的问号，需要跳过
    ignoreQueryPrefix: true,
    comma: true,
  });
};
