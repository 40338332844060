// Customized antd theme, refer to https://ant.design/docs/react/use-with-create-react-app-cn#%E9%AB%98%E7%BA%A7%E9%85%8D%E7%BD%AE
// for details. The theme is applied by craco less plugin.
module.exports = {
  "@font-size-base": "16px",
  "@tag-font-size": "16px",
  "@tag-line-height": "26px",
  "@border-radius-base": "4px",
  lightPrimary: "#40a9ff",
  primary: "#1890ff",
  success: "#52c41a",
  danger: "#ff4d4f",
  warning: "#fadb14",
};
