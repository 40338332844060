import React from "react";
import "./common/axios";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { message } from "antd";
import store from "./common/redux";
import { Provider } from "react-redux";
import AppRouter from "./AppRouter";

const App = () => {
  // 全局message配置
  message.config({ duration: 3, top: 100, maxCount: 3 });

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={`%s - Knowledge Base`}
        defaultTitle={"Knowledge Base"}
      />
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </HelmetProvider>
  );
};

export default App;
