import { Menu, Spin } from "antd";
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ColumnCenterContainer } from "../common/components";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState } from "react";
import { useMount } from "react-use";
import {
  setCategories,
  getCategories,
  ALL_CATEGORIES_ITEM,
  changeSelectedCategory,
} from "./slice";
const { SubMenu } = Menu;

const Categories = () => {
  const { pathname } = useLocation();
  const categoryParams = _.split(pathname, "/")[2];
  const categoryId = categoryParams === "all" ? "0" : categoryParams;

  const [loading, setLoading] = useState(true);
  const [categoryMenuItems, setCategoryMenuItems] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const dispatch = useDispatch();

  useMount(async () => {
    await loadCategories();
  });

  const loadCategories = async () => {
    setLoading(true);
    const data = (await getCategories()).data.content;
    const categories = [ALL_CATEGORIES_ITEM, ...data];
    dispatch(setCategories(categories));
    const category = _.find(categories, ["id", categoryId]);
    if (!_.isUndefined(category)) {
      dispatch(changeSelectedCategory(category));
    }
    setOpenKeys([String(category?.parentId)]);
    // 子菜单已经在父菜单的children中，无需再单独列出
    const items = _.filter(categories, ["parentId", "0"]);
    setCategoryMenuItems(items);
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <ColumnCenterContainer>
          <Spin
            css={css`
              padding: 5rem 0;
            `}
          />
        </ColumnCenterContainer>
      ) : (
        <Menu
          selectedKeys={[categoryId]}
          defaultOpenKeys={openKeys}
          mode="inline"
        >
          {categoryMenuItems.map((category) => renderCategory(category))}
        </Menu>
      )}
    </>
  );
};

const renderCategory = (category) => {
  if (_.isEmpty(category.children)) {
    return (
      <Menu.Item key={category.id}>
        <Link to={category.path}>{category.name}</Link>
      </Menu.Item>
    );
  } else {
    return (
      <SubMenu key={category.id} title={category.name}>
        {category.children.map((child) => {
          return (
            <Menu.Item key={child.id}>
              <Link to={child.path}>{child.name}</Link>
            </Menu.Item>
          );
        })}
      </SubMenu>
    );
  }
};

export default Categories;
