import axios from "axios";
import qs from "qs";
import _ from "lodash";
import { history } from "./router";
import { message } from "antd";

// 修改数据参数的序列化方法，参数如果是数组，会用逗号分隔
axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: "comma" });
};

axios.defaults.baseURL = "/api";

const handleUnauth = () => {
  message.warning("尚未登录");
};

// 防止短时间内大量调用都返回401，此时会弹出很多相同的消息提示，用debounce使得提示消息只显示一次
const debouncedHandleUnauth = _.debounce(handleUnauth, 1000);

axios.interceptors.response.use(null, (error) => {
  const { config, response } = error;
  if (response) {
    // 未登录跳转到登陆页面
    if (response.status === 401) {
      debouncedHandleUnauth();
    } else if (response.status === 403) {
      message.error("权限不足，请联系管理员！");
    } else if (response.status === 404) {
      history.push("/404");
      message.error("该页面不存在！");
    } else {
      // 自定义API错误全局处理，默认打开，需要request调用时显式关闭
      if (config.useGlobalErrorHandler !== false) {
        const msg = getErrorMessage(error);
        message.error(msg);
      }
    }
  }

  throw error;
});

// Retrieve the error message from error.
export const getErrorMessage = (error) => {
  // First try to get message from an error response, else it may be an unexpected error.
  return (
    _.get(error, "response.data.status.message") ||
    error.message ||
    `意外错误，请联系研发团队：${error.stack}`
  );
};
